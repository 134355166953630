import React, { useEffect, useState } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { loginStart } from "../../store/actions/UserAction";

const LoginIndex = (props) => {

  const [skipRender, setSkipRender] = useState(true);
  const [loginPasswordVisible, setLoginPasswordVisible] = useState(false);

  const navigate = useNavigate();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(("Invalid Email")).required("Email Required"),
    password: Yup.string()
      .required(("Password Required"))
      .matches(/^(?=.*[a-zA-Z0-9])(?=.{6,})/, ("Must Have 6 Characters")),
  });

  const handleLogin = (values) => {
    props.dispatch(loginStart(values));
  };

  useEffect(() => {
    if (localStorage.getItem("userId") && localStorage.getItem("accessToken")) {
      navigate("/profile");
    }
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !props.login.loading &&
      Object.keys(props.login.data).length > 0
    ) {
      if (props.login.data.is_two_step_auth_enabled == 1) {
        navigate("/verification");
      } else if (props.login.data.is_email_verified == 0) {
        navigate("/register-verification");
      } else {
        navigate("/profile");
      }
    }
    setSkipRender(false);
  }, [props.login]);

  return (
    <>
      <div className="login-sec">
        <div className="login-box">
          <Container>
            <Row className="justify-content-md-center">
              <Col md={12} xl={5} lg={5}>
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                  }}
                  validationSchema={loginSchema}
                  onSubmit={(values) => handleLogin(values)}
                >
                  {({ touched, errors }) => (
                    <FORM className="exchange-form">
                      {/* <Form className="auth-form-sec"> */}
                      <div className="exchange-box">
                        {/* <div className="auth-logo-sec">
                          <Link to="/">
                            <Image
                              className="logo"
                              src="../img/logo-exchange.png"
                            />
                          </Link>
                        </div> */}
                        <h2>Welcome!</h2>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Email <span>*</span>
                          </Form.Label>
                          <Field
                            type="email"
                            name="email"
                            placeholder={("Enter Your Email")}
                            className="form-control"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="email"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className="mb-3">
                          <Form.Label>
                            Password <span>*</span>
                          </Form.Label>
                          <InputGroup className="mb-0">
                            <Field
                              type={loginPasswordVisible ? "text" : "password"}
                              name="password"
                              placeholder={("Enter Your Password")}
                              className="form-control"
                            />
                            <InputGroup.Text
                              id="basic-addon2"
                              onClick={() =>
                                setLoginPasswordVisible(!loginPasswordVisible)
                              }
                            >
                              {loginPasswordVisible ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <g clipPath="url(#clip0_1_56)">
                                    <path
                                      fill="#3C3C41"
                                      d="M12 6.5c2.76 0 5 2.24 5 5 0 .51-.1 1-.24 1.46l3.06 3.06c1.39-1.23 2.49-2.77 3.18-4.53C21.27 7.11 17 4 12 4c-1.27 0-2.49.2-3.64.57l2.17 2.17c.47-.14.96-.24 1.47-.24zM2.71 3.16a.996.996 0 000 1.41l1.97 1.97A11.892 11.892 0 001 11.5C2.73 15.89 7 19 12 19c1.52 0 2.97-.3 4.31-.82l2.72 2.72a.996.996 0 101.41-1.41L4.13 3.16c-.39-.39-1.03-.39-1.42 0zM12 16.5c-2.76 0-5-2.24-5-5 0-.77.18-1.5.49-2.14l1.57 1.57c-.03.18-.06.37-.06.57 0 1.66 1.34 3 3 3 .2 0 .38-.03.57-.07L14.14 16c-.65.32-1.37.5-2.14.5zm2.97-5.33a2.97 2.97 0 00-2.64-2.64l2.64 2.64z"
                                    ></path>
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1_56">
                                      <path
                                        fill="#fff"
                                        d="M0 0H24V24H0z"
                                      ></path>
                                    </clipPath>
                                  </defs>
                                </svg>
                              ) : (
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 19 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.5 1.5C5.86985 1.5 2.57784 3.77968 0.148665 7.4825C-0.0495551 7.78586 -0.0495551 8.20968 0.148665 8.51304C2.57784 12.2203 5.86985 14.5 9.5 14.5C13.1302 14.5 16.4222 12.2203 18.8513 8.5175C19.0496 8.21414 19.0496 7.79032 18.8513 7.48696C16.4222 3.77968 13.1302 1.5 9.5 1.5ZM9.76041 12.5772C7.35067 12.7512 5.36069 10.4715 5.51227 7.7011C5.63665 5.41695 7.24962 3.56555 9.23959 3.42279C11.6493 3.2488 13.6393 5.52848 13.4877 8.2989C13.3595 10.5786 11.7465 12.43 9.76041 12.5772ZM9.63992 10.4626C8.34177 10.5563 7.26905 9.32944 7.35456 7.8394C7.42063 6.6081 8.29124 5.61325 9.36397 5.53294C10.6621 5.43926 11.7348 6.6661 11.6493 8.15614C11.5794 9.3919 10.7088 10.3868 9.63992 10.4626Z"
                                    fill="#3C3C41"
                                  />
                                </svg>
                              )}
                            </InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            component={"div"}
                            name="password"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        <div className="exchange-btn-sec">
                          <Button
                            className="default-btn w-100"
                            type="submit"
                            disabled={props.login.buttonDisable}
                          >
                            {props.login.buttonDisable
                              ? "Loading"
                              : "Login"}
                          </Button>
                        </div>
                        <div className="auth-footer-link">
                          <span>New to Cryptoexh?</span>
                          <Link to="/register" className="">
                            Sign Up Now
                          </Link>
                        </div>
                      </div>
                      {/* </Form> */}
                    </FORM>
                  )}
                </Formik>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  login: state.user.login,
  register: state.user.register,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(
  mapStateToPros,
  mapDispatchToProps
)(LoginIndex);
