import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure
} from "../utils";
import {
  BUY_TOKEN_FAILURE,
  BUY_TOKEN_START,
  BUY_TOKEN_SUCCESS,
  CREATE_KYC_APPLICANT_FAILURE,
  CREATE_KYC_APPLICANT_START,
  CREATE_KYC_APPLICANT_SUCCESS,
  GENERATE_STRIPE_PAYMENT_FAILURE,
  GENERATE_STRIPE_PAYMENT_START,
  GENERATE_STRIPE_PAYMENT_SUCCESS,
  SWAP_TOKEN_START,
  SWAP_TOKEN_SUCCESS,
  SWAP_TOKEN_FAILURE,
  SET_SWAP_DATA,
  SET_BUY_DATA,
  RESET_BUY_DATA,
  RESET_SWAP_DATA,
  RESET_BANK_DATA,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  PROFILE_START,
  PROFILE_FAILURE,
  PROFILE_SUCCESS,
  UPDATE_KYC_START,
  UPDATE_KYC_SUCCESS,
  UPDATE_KYC_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  STYLOPAY_PAYMENT_FAILURE,
  STYLOPAY_PAYMENT_START,
  STYLOPAY_PAYMENT_SUCCESS,
  TRANSATIONS_LIST_FAILURE,
  TRANSATIONS_LIST_START,
  TRANSATIONS_LIST_SUCCESS,
  FETCH_MORE_TRANSATIONS,
  BANK_PAYMENT_FAILURE,
  BANK_PAYMENT_START,
  BANK_PAYMENT_SUCCESS,
  BANK_VIEW_START,
  BANK_VIEW_SUCCESS,
  BANK_VIEW_FAILURE,
  LIR_PAYMENT_FAILURE,
  LIR_PAYMENT_START,
  LIR_PAYMENT_SUCCESS,
} from "../actions/ActionConstant"

export const initialBuyData = {
  step: 1,
  token_type: null,
  token: 0.5,
  receiver_wallet_address: "",
  accessToken: "",
  kyc_authCode: "",
  payment_id: "",
  exchangeValue: 0,
  amountConversion: 0,
}

export const initialSwapData = {
  step: 1,
  swap_token: 0.1,
  swap_token_type: null,
  token_type: null,
  receiver_wallet_address: "",
  accessToken: "",
  kyc_authCode: "",
  swap_transaction_hash: "",
  exchangeValue: 0,
  amountConversion: 0,
}

const initialState = {
  buyData: initialBuyData,
  swapData: initialSwapData,
  generateStripe: getCommonInitialState(),
  buyToken: getCommonInitialState(),
  kycApplicant: getCommonInitialState(),
  swapToken: getCommonInitialState(),
  login: getCommonInitialState(),
  register: getCommonInitialState(),
  profile: getCommonInitialState(),
  updateProfile: getCommonInitialState(),
  updateKYC: getCommonInitialState(),
  stylopayPayment: getCommonInitialState(),
  lirPayment: getCommonInitialState(),
  bankPayment: getCommonInitialState(),
  transactions: getCommonInitialState(),
  bankView: getCommonInitialState(),
}

const UserReducer = (state = initialState, action) => {
  switch (action.type) {

    case GENERATE_STRIPE_PAYMENT_START: return { ...state, generateStripe: getCommonStart() };
    case GENERATE_STRIPE_PAYMENT_SUCCESS: return { ...state, generateStripe: getCommonSuccess(action.data) };
    case GENERATE_STRIPE_PAYMENT_FAILURE: return { ...state, generateStripe: getCommonFailure(action.error) };

    case BUY_TOKEN_START: return { ...state, buyToken: getCommonStart() };
    case BUY_TOKEN_SUCCESS: return { ...state, buyToken: getCommonSuccess(action.data) };
    case BUY_TOKEN_FAILURE: return { ...state, buyToken: getCommonFailure(action.error) };

    case CREATE_KYC_APPLICANT_START: return { ...state, kycApplicant: getCommonStart() };
    case CREATE_KYC_APPLICANT_SUCCESS: return { ...state, kycApplicant: getCommonSuccess(action.data) };
    case CREATE_KYC_APPLICANT_FAILURE: return { ...state, kycApplicant: getCommonFailure(action.error) };

    case SWAP_TOKEN_START: return { ...state, swapToken: getCommonStart() };
    case SWAP_TOKEN_SUCCESS: return { ...state, swapToken: getCommonSuccess(action.data) };
    case SWAP_TOKEN_FAILURE: return { ...state, swapToken: getCommonFailure(action.error) };

    case SET_BUY_DATA: return { ...state, buyData: action.data };
    case RESET_BUY_DATA: return {
      ...state, buyData: {
        ...initialBuyData,
        token_type: state.buyData.token_type,
        token: state.buyData.token,
        exchangeValue: state.buyData.exchangeValue,
        amountConversion: state.buyData.amountConversion,
      }
    };
    case SET_SWAP_DATA: return { ...state, swapData: action.data };
    case RESET_SWAP_DATA: return {
      ...state, swapData: {
        ...initialSwapData,
        token_type: state.swapData.token_type,
        swap_token_type: state.swapData.swap_token_type,
        swap_token: state.swapData.swap_token,
        exchangeValue: state.swapData.exchangeValue,
        amountConversion: state.swapData.amountConversion,
      }
    };
    case RESET_BANK_DATA: return { ...state, bankPayment: getCommonSuccess({}) };

    case TRANSATIONS_LIST_START: return { ...state, transactions: getCommonStart({ transactions: [], transactions_count: 0 }) };
    case FETCH_MORE_TRANSATIONS: return { ...state };
    case TRANSATIONS_LIST_SUCCESS: return { ...state, transactions: getCommonSuccess(action.data) };
    case TRANSATIONS_LIST_FAILURE: return { ...state, transactions: getCommonFailure(action.error) };

    case LOGIN_START: return { ...state, login: getCommonStart() };
    case LOGIN_SUCCESS: return { ...state, login: getCommonSuccess(action.data) };
    case LOGIN_FAILURE: return { ...state, login: getCommonFailure(action.error) };

    case REGISTER_START: return { ...state, register: getCommonStart() };
    case REGISTER_SUCCESS: return { ...state, register: getCommonSuccess(action.data) };
    case REGISTER_FAILURE: return { ...state, register: getCommonFailure(action.error) }; 

    case PROFILE_START: return { ...state, profile: getCommonStart() };
    case PROFILE_SUCCESS: return { ...state, profile: getCommonSuccess(action.data) };
    case PROFILE_FAILURE: return { ...state, profile: getCommonFailure(action.error) };

    case UPDATE_KYC_START: return { ...state, updateKYC: getCommonStart() };
    case UPDATE_KYC_SUCCESS: return { ...state, updateKYC: getCommonSuccess(action.data) };
    case UPDATE_KYC_FAILURE: return { ...state, updateKYC: getCommonFailure(action.error) }; 

    case UPDATE_PROFILE_START: return { ...state, updateProfile: getCommonStart() };
    case UPDATE_PROFILE_SUCCESS: return { ...state, updateProfile: getCommonSuccess(action.data) };
    case UPDATE_PROFILE_FAILURE: return { ...state, updateProfile: getCommonFailure(action.error) }; 

    case STYLOPAY_PAYMENT_START: return { ...state, stylopayPayment: getCommonStart() };
    case STYLOPAY_PAYMENT_SUCCESS: return { ...state, stylopayPayment: getCommonSuccess(action.data) };
    case STYLOPAY_PAYMENT_FAILURE: return { ...state, stylopayPayment: getCommonFailure(action.error) };

    case BANK_PAYMENT_START: return { ...state, bankPayment: getCommonStart() };
    case BANK_PAYMENT_SUCCESS: return { ...state, bankPayment: getCommonSuccess(action.data) };
    case BANK_PAYMENT_FAILURE: return { ...state, bankPayment: getCommonFailure(action.error) };

    case BANK_VIEW_START: return { ...state, bankView: getCommonStart() };
    case BANK_VIEW_SUCCESS: return { ...state, bankView: getCommonSuccess(action.data) };
    case BANK_VIEW_FAILURE: return { ...state, bankView: getCommonFailure(action.error) };

    case LIR_PAYMENT_START: return { ...state, lirPayment: getCommonStart() };
    case LIR_PAYMENT_SUCCESS: return { ...state, lirPayment: getCommonSuccess(action.data) };
    case LIR_PAYMENT_FAILURE: return { ...state, lirPayment: getCommonFailure(action.error) };

    default:
      return state;
  }
}

export default UserReducer;