import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { lirPaymentStart } from '../../store/actions/UserAction';

const USDPayIndex = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buyData = useSelector(state => state.user.buyData);
  const lirPayment = useSelector(state => state.user.lirPayment);

  const validationSchema = Yup.object().shape({
    card_holder_name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid Email").required("Email is required"),
    amount: Yup.string().required("Amount is required")
      .test('valid-amount', 'Invalid amount', (value) => {
        const intValue = parseInt(value, 10);
        return intValue >= 1;
      }),
    card_number: Yup.string().required("Card number is required")
      .matches(/^[0-9]{16}$/, "Invalid card"),
    cvv: Yup.string().required("CVV is required")
      .matches(/^[0-9]{3}$/, "Invalid CVV"),
    expiry_month: Yup.string()
      .required("Month is required")
      .test('valid-month', 'Invalid month', (value) => {
        const intValue = parseInt(value, 10);
        return intValue >= 1 && intValue <= 12;
      })
      .transform((value) => (value.length === 1 ? `0${value}` : value)),
    expiry_year: Yup.string()
      .required("Year is required")
      .test('valid-year', 'Invalid year', (value) => {
        const intValue = parseInt(value, 10);
        return intValue >= 2023 && intValue <= 2099;
      })

  });

  const stylepayPaymentSubmit = (values) => {
    dispatch(lirPaymentStart(values));
  };

  useEffect(() => {
    if (!lirPayment.loading && Object.keys(lirPayment.data).length > 0) {
      window.location.replace(lirPayment.data.redirect_url)
    }
  }, [lirPayment])

  return (
    <>
      <Formik
        initialValues={{
          card_holder_name: "",
          email: "",
          amount: "",
          card_number: "",
          cvv: "",
          expiry_month: "",
          expiry_year: "",
        }}
        validationSchema={validationSchema}
        onSubmit={stylepayPaymentSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <FORM className="exchange-form">
            <div className="exchange-box-1">
              <h2>Card Details</h2>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Card Holder Name</Form.Label>
                <Field
                  type="text"
                  className="form-control"
                  name="card_holder_name"
                  placeholder="Enter name on the card"
                />
                <ErrorMessage
                  component={"div"}
                  name="card_holder_name"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Email</Form.Label>
                <Field
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter Email"
                />
                <ErrorMessage
                  component={"div"}
                  name="email"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Amount</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="amount"
                  min='10'
                  placeholder="Enter Amount"
                />
                <ErrorMessage
                  component={"div"}
                  name="amount"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Card No</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="card_number"
                  placeholder="Enter card number"
                />
                <ErrorMessage
                  component={"div"}
                  name="card_number"
                  className="text-danger"
                />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formExpiryMonth">
                    <Form.Label>Expiry Month</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="expiry_month"
                      placeholder="MM"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length === 1 && value !== '0' && value !== '1') {
                          setFieldValue('expiry_month', `0${value}`);
                        } else {
                          setFieldValue('expiry_month', value);
                        }
                      }}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="expiry_month"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formExpiryYear">
                    <Form.Label>Expiry Year</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="expiry_year"
                      placeholder="YYYY"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="expiry_year"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="formCVV">
                <Form.Label>CVV</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="cvv"
                  placeholder="Enter CVV"
                />
                <ErrorMessage
                  component={"div"}
                  name="cvv"
                  className="text-danger"
                />
              </Form.Group>
              <div className="exchange-btn-sec">
                <Button
                  className="default-btn w-100"
                  type="submit"
                  disabled={lirPayment.buttonDisable}
                >
                  {lirPayment.buttonDisable
                    ? "Loading"
                    : "Submit"}
                </Button>
              </div>
            </div>
          </FORM>
        )}
      </Formik>
    </>
  );
};

export default USDPayIndex;
