import './App.css';
import React, { useState, useEffect } from 'react';
import Base from "./components/Base";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-toastify/dist/ReactToastify.css';
import store from "./store";
import { Provider } from "react-redux";
import { apiConstants } from './components/Constant/constants'
import configuration from 'react-global-configuration'
import 'react-loading-skeleton/dist/skeleton.css'

function App() {
  const [configLoading, setConfigLoading] = useState(true);

  useEffect(() => {
    fetchConfig();
  }, []);

  const fetchConfig = async () => {
    try {
      const response = await fetch(apiConstants.settingsUrl);
      const configValue = await response.json();
      configuration.set({ configData: configValue.data }, { freeze: false });
      setConfigLoading(false);
    } catch (error) {
      configuration.set({ configData: [] }, { freeze: false });
      setConfigLoading(false);
    }
  };

  return (
    <Provider store={store}>
      {configLoading ?
        null
        : <Base />
      }
    </Provider>
  );
}

export default App;
