import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import { getCommonSaga } from '../sagaUtils';
import store from '../index';
import {
  BUY_TOKEN_START,
  CREATE_KYC_APPLICANT_START,
  GENERATE_STRIPE_PAYMENT_START,
  LOGIN_START,
  PROFILE_START,
  REGISTER_START,
  SWAP_TOKEN_START,
  UPDATE_KYC_START,
  UPDATE_PROFILE_START,
  STYLOPAY_PAYMENT_START,
  LIR_PAYMENT_START,
  BANK_PAYMENT_START,
  TRANSATIONS_LIST_START,
  FETCH_MORE_TRANSATIONS,
  BANK_VIEW_START,
} from '../actions/ActionConstant';
import {
  generateStripePaymentSuccess,
  generateStripePaymentFailure,
  buyTokenSuccess,
  buyTokenFailure,
  createKycApplicantSuccess,
  createKycApplicantFailure,
  swapTokenSuccess,
  swapTokenFailure,
  updateBuyData,
  updateSwapData,
  registerSuccess,
  registerFailure,
  loginSuccess,
  loginFailure,
  profileSuccess,
  profileFailure,
  updateKYCSuccess,
  updateKYCFailure,
  profileUpdateSuccess,
  profileUpdateFailure,
  stylopayPaymentSuccess,
  stylopayPaymentFailure,
  lirPaymentSuccess,
  lirPaymentFailure,
  transactionsSuccess,
  transactionsFailure,
  bankPaymentSuccess,
  bankPaymentFailure,
  bankViewSuccess,
  bankViewFailure,
} from '../actions/UserAction';


function* generateStripePaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "generate_stripe_payment",
    payload: action.data,
    success: generateStripePaymentSuccess,
    failure: generateStripePaymentFailure,
  });
}

function* buyTokenAPI(action) {
  const buyData = yield select(state => state.user.buyData)
  yield getCommonSaga({
    apiUrl: "user_buy_tokens",
    payload: action.data,
    successCallback: true,
    failureCallback: true,
    success: data => {
      store.dispatch(buyTokenSuccess(data));
      store.dispatch(updateBuyData({ ...buyData, token: 0.1, token_type: null, step: 6 }));
    },
    failure: error => {
      store.dispatch(buyTokenFailure(error));
      store.dispatch(updateBuyData({ ...buyData, token: 0.1, token_type: null, step: 6 }));
    },
  });
}

function* createKycApplicantAPI(action) {
  yield getCommonSaga({
    apiUrl: "kyc_create_applicant",
    payload: action.data,
    success: createKycApplicantSuccess,
    failure: createKycApplicantFailure,
  });
}

function* swapTokenAPI(action) {
  const swapData = yield select(state => state.user.swapData)
  yield getCommonSaga({
    apiUrl: "user_swap_tokens",
    payload: action.data,
    successCallback: true,
    failureCallback: true,
    success: data => {
      store.dispatch(swapTokenSuccess(data))
      store.dispatch(updateSwapData({
        ...swapData, swap_token: 0.1,
        swap_token_type: null,
        token_type: null, step: 6
      }));
    },
    failure: error => {
      store.dispatch(swapTokenFailure(error));
      store.dispatch(updateSwapData({
        ...swapData, swap_token: 0.1,
        swap_token_type: null,
        token_type: null, step: 6
      }));
    },
  });
}

// Auth
function* userRegisterAPI(action) {
  yield getCommonSaga({
    apiUrl: "register",
    payload: action.data,
    successCallback: true,
    success: data => {
      store.dispatch(registerSuccess(data));
      store.dispatch(loginSuccess(data));
      store.dispatch(profileSuccess(data.user));
      localStorage.setItem("userId", data.user.user_id);
      localStorage.setItem("token", data.access_token);
    },
    failure: registerFailure,
  });
}

function* userLoginAPI(action) {
  yield getCommonSaga({
    apiUrl: "login",
    payload: action.data,
    successCallback: true,
    success: data => {
      store.dispatch(loginSuccess(data))
      store.dispatch(profileSuccess(data.user))
      localStorage.setItem("token", data.access_token);
      localStorage.setItem("userId", data.user.user_id);
    },
    failure: loginFailure,
  });
}

function* profileAPI(action) {
  yield getCommonSaga({
    apiUrl: "profile",
    payload: action.data,
    successCallback: true,
    success: data => {
      store.dispatch(profileSuccess(data.user));
    },
    failure: profileFailure,
  });
}

function* updateProfileAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_profile",
    payload: action.data,
    successCallback: true,
    success: data => {
      store.dispatch(profileUpdateSuccess(data.user));
    },
    failure: profileUpdateFailure,
  });
}

function* updateKYCAPI(action) {
  yield getCommonSaga({
    apiUrl: "update_kyc",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: data => {
      store.dispatch(updateKYCSuccess(data));
      store.dispatch(profileSuccess(data.user));
    },
    failure: updateKYCFailure,
  });
}

function* stylopayPaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "token_payment_by_stylopay",
    payload: action.data,
    success: stylopayPaymentSuccess,
    failure: stylopayPaymentFailure,
  });
}

function* lirPaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "token_payment_by_lir",
    payload: action.data,
    success: lirPaymentSuccess,
    failure: lirPaymentFailure,
  });
}

function* bankPaymentAPI(action) {
  yield getCommonSaga({
    apiUrl: "token_payment_by_bank",
    payload: action.data,
    success: bankPaymentSuccess,
    failure: bankPaymentFailure,
  });
}

function* transactionsAPI(action) {
  const transactionsList = yield select(state => state.user.transactions.data)
  yield getCommonSaga({
    apiUrl: "transaction_list",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: data => {
      if(Object.keys(transactionsList).length > 0) {
        store.dispatch(transactionsSuccess({
          transactions: [...transactionsList.transactions, ...data.transactions],
          transactions_count: data.transactions_count
        }))
      }
    },
    failure: transactionsFailure,
  });
}

function* bankViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "admin_bank_view",
    payload: action.data,
    success: bankViewSuccess,
    failure: bankViewFailure,
  });
}

export default function* UserSaga() {
  yield all([
    yield takeLatest(GENERATE_STRIPE_PAYMENT_START, generateStripePaymentAPI),
    yield takeLatest(BUY_TOKEN_START, buyTokenAPI),
    yield takeLatest(CREATE_KYC_APPLICANT_START, createKycApplicantAPI),
    yield takeLatest(SWAP_TOKEN_START, swapTokenAPI),
    yield takeLatest(REGISTER_START, userRegisterAPI),
    yield takeLatest(LOGIN_START, userLoginAPI),
    yield takeLatest(PROFILE_START, profileAPI),
    yield takeLatest(UPDATE_PROFILE_START, updateProfileAPI),
    yield takeLatest(UPDATE_KYC_START, updateKYCAPI),
    yield takeLatest(STYLOPAY_PAYMENT_START, stylopayPaymentAPI),
    yield takeLatest(LIR_PAYMENT_START, lirPaymentAPI),
    yield takeLatest(BANK_PAYMENT_START, bankPaymentAPI),
    yield takeLatest(TRANSATIONS_LIST_START, transactionsAPI),
    yield takeLatest(FETCH_MORE_TRANSATIONS, transactionsAPI),
    yield takeLatest(BANK_VIEW_START, bankViewAPI)
  ]);
}