import React, { useEffect, useState } from "react";
import {
  Container,
  Image,
  Nav,
  Row,
  Col,
  Table,
  Button,
  Tab,
} from "react-bootstrap";
import "./Transaction.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  transactionsStart,
  fetchMoreTransactionsStart,
} from "../../store/actions/UserAction";
import PageLoader from "../helper/PageLoader";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import SomethingWentWrong from "../Common/SomethingWentWrong";
import InfiniteScroll from "react-infinite-scroll-component";
import NoDataFound from "../helper/NoDataFound";

const TransactionIndex = (props) => {
  const dispatch = useDispatch();

  const [type, setType] = useState("");

  useEffect(() => {
    let data = { skip: 0, take: 12 };
    if (type) data = { ...data, payment_type: type };
    dispatch(transactionsStart(data));
  }, [type]);

  const fetchMoreTransactions = () => {
    dispatch(
      fetchMoreTransactionsStart({
        payment_type: type,
        skip: props.transactions.data.transactions.length,
        take: 12,
      })
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 0:
        return "payment-pending";
      case 1:
        return "payment-approved";
      case 2:
        return "payment-rejected";
      case 3:
        return "payment-dispute";
      default:
        return "payment-pending";
    }
  }

  return (
    <>
      <div className="trade-sec setting-sec">
        <Container>
          <Row>
            <Col md={12}>
              <div className="list-table-sec transaction-table-sec">
                <Tab.Container id="left-tabs-example" defaultActiveKey={type}>
                  <Row>
                    <Col sm={12}>
                      <Nav variant="pills" defaultActiveKey="">
                        <Nav.Item>
                          <Nav.Link
                            eventKey=""
                            active={type === ""}
                            onClick={() =>
                              setType("")
                            }
                          >
                           All
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="1"
                            onClick={() =>
                              setType("1")
                            }
                          >
                           Card
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="2"
                            onClick={() =>
                              setType("2")
                            }
                          >
                           Bank
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link
                            eventKey="3"
                            onClick={() =>
                              setType("3")
                            }
                          >
                           USD
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </Col>
                    {props.transactions.loading ? (
                      <PageLoader />
                    ) : props.transactions.data.transactions ? (
                      props.transactions.data.transactions.length >
                      0 ? (
                        <InfiniteScroll
                          dataLength={
                            props.transactions.data.transactions.length
                          }
                          next={fetchMoreTransactions}
                          hasMore={
                            props.transactions.data.transactions
                              .length <
                              props.transactions.data.transactions_count
                          }
                          loader={
                            <div className="d-flex justify-content-center mb-5">
                              <CommonCenterLoader />
                            </div>
                          }
                        >
                          <Col sm={12}>
                            <Table>
                              <thead>
                                <tr>
                                  <th>S.No.</th>
                                  <th>Transaction Id</th>
                                  <th>Type</th>
                                  <th>Amount</th>
                                  <th>Created At</th>
                                  <th>Payment Status</th>
                                  <th>Payment Screenshot</th>
                                </tr>
                              </thead>
                              <tbody>
                                {props.transactions.data.transactions.map(
                                  (transaction, i) => (
                                    <tr key={i}>
                                      <td>
                                        <div className="list-user-details">
                                          <div className="list-user-info">
                                            <p>{i+1}</p>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="list-price-details">
                                          <div className="price-amount">
                                            <span>
                                              {transaction.transaction_unique_id}
                                            </span>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="price-convertion-amount">
                                            <p>
                                              {transaction.payment_type_formatted}
                                            </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className="price-convertion-amount">
                                              <p>
                                                {transaction.amount_formatted}
                                              </p>
                                          </div>
                                      </td>
                                      <td>
                                        <div className="price-convertion-amount">
                                            <p>
                                              {transaction.created_at}
                                            </p>
                                        </div>
                                      </td>
                                      <td>
                                        <div className={getStatusClass(transaction.payment_status)}>
                                          {transaction.payment_status_formatted}
                                        </div>
                                      </td>
                                      <td>
                                        <div className="price-convertion-amount">
                                            <p>
                                              {transaction.picture ? <a href={transaction.picture} rel="noreferrer" target="_blank">View Screenshot</a> : "N/A"}
                                            </p>
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </Table>
                          </Col>
                        </InfiniteScroll>
                      ) : (
                        <NoDataFound />
                      )
                    ) : (
                      <SomethingWentWrong />
                    )}
                  </Row>
                </Tab.Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  transactions: state.user.transactions,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(TransactionIndex);

