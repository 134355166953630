//Wallet Connector
export const SET_WALLET_DETAILS = "SET_WALLET_DETAILS";

export const AMOUNT_CONVERSION_START = "AMOUNT_CONVERSION_START";
export const AMOUNT_CONVERSION_SUCCESS = "AMOUNT_CONVERSION_SUCCESS";
export const AMOUNT_CONVERSION_FAILURE = "AMOUNT_CONVERSION_FAILURE";

export const GENERATE_STRIPE_PAYMENT_START = "GENERATE_STRIPE_PAYMENT_START";
export const GENERATE_STRIPE_PAYMENT_SUCCESS = "GENERATE_STRIPE_PAYMENT_SUCCESS";
export const GENERATE_STRIPE_PAYMENT_FAILURE = "GENERATE_STRIPE_PAYMENT_FAILURE";

export const BUY_TOKEN_START = "BUY_TOKEN_START";
export const BUY_TOKEN_SUCCESS = "BUY_TOKEN_SUCCESS";
export const BUY_TOKEN_FAILURE = "BUY_TOKEN_FAILURE";

export const CREATE_KYC_APPLICANT_START = "CREATE_KYC_APPLICANT_START";
export const CREATE_KYC_APPLICANT_SUCCESS = "CREATE_KYC_APPLICANT_SUCCESS";
export const CREATE_KYC_APPLICANT_FAILURE = "CREATE_KYC_APPLICANT_FAILURE";

export const SWAP_TOKEN_START = "SWAP_TOKEN_START";
export const SWAP_TOKEN_SUCCESS = "SWAP_TOKEN_SUCCESS";
export const SWAP_TOKEN_FAILURE = "SWAP_TOKEN_FAILURE";

export const SET_BUY_DATA = "SET_BUY_DATA";
export const RESET_BUY_DATA = "RESET_BUY_DATA";
export const SET_SWAP_DATA = "SET_SWAP_DATA";
export const RESET_SWAP_DATA = "RESET_SWAP_DATA";
export const RESET_BANK_DATA = "RESET_BANK_DATA";

export const TRANSATIONS_LIST_START = "TRANSATIONS_LIST_START";
export const FETCH_MORE_TRANSATIONS = "FETCH_MORE_TRANSATIONS";
export const TRANSATIONS_LIST_SUCCESS = "TRANSATIONS_LIST_SUCCESS";
export const TRANSATIONS_LIST_FAILURE = "TRANSATIONS_LIST_FAILURE";

export const FETCH_STATIC_PAGE_START = "FETCH_STATIC_PAGE_START";
export const FETCH_STATIC_PAGE_SUCCESS = "FETCH_STATIC_PAGE_SUCCESS";
export const FETCH_STATIC_PAGE_FAILURE = "FETCH_STATIC_PAGE_FAILURE";

// FAQ Lists
export const FETCH_FAQS_LIST_START = "FETCH_FAQS_LIST_START";;
export const FETCH_FAQS_LIST_SUCCESS = "FETCH_FAQS_LIST_SUCCESS";
export const FETCH_FAQS_LIST_FAILURE = "FETCH_FAQS_LIST_FAILURE";

// Auth
export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

//Profile
export const PROFILE_START = "PROFILE_START";
export const PROFILE_SUCCESS = "PROFILE_SUCCESS";
export const PROFILE_FAILURE = "PROFILE_FAILURE";

export const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

// Kyc update

export const UPDATE_KYC_START = "UPDATE_KYC_START";
export const UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS";
export const UPDATE_KYC_FAILURE = "UPDATE_KYC_FAILURE";

export const STYLOPAY_PAYMENT_START = "STYLOPAY_PAYMENT_START";
export const STYLOPAY_PAYMENT_SUCCESS = "STYLOPAY_PAYMENT_SUCCESS";
export const STYLOPAY_PAYMENT_FAILURE = "STYLOPAY_PAYMENT_FAILURE";

export const BANK_PAYMENT_START = "BANK_PAYMENT_START";
export const BANK_PAYMENT_SUCCESS = "BANK_PAYMENT_SUCCESS";
export const BANK_PAYMENT_FAILURE = "BANK_PAYMENT_FAILURE";

// Bank View
export const BANK_VIEW_START = "BANK_VIEW_START";
export const BANK_VIEW_SUCCESS = "BANK_VIEW_SUCCESS";
export const BANK_VIEW_FAILURE = "BANK_VIEW_FAILURE";

export const LIR_PAYMENT_START = "LIR_PAYMENT_START";
export const LIR_PAYMENT_SUCCESS = "LIR_PAYMENT_SUCCESS";
export const LIR_PAYMENT_FAILURE = "LIR_PAYMENT_FAILURE";