import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  bankPaymentStart,
  bankViewStart,
} from "../../store/actions/UserAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import NoDataFound from "../helper/NoDataFound";

const BankPaymentIndex = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buyData = useSelector((state) => state.user.buyData);
  const bankPayment = useSelector((state) => state.user.bankPayment);
  const bankView = useSelector((state) => state.user.bankView);
  const [screenshot, setScreenshot] = useState({
    preview: "",
    file: "",
  });

  const validationSchema = Yup.object().shape({
    amount: Yup.string().required("Amount is required"),
    payment_id: Yup.string().required("Payment Id is required"),
  });

  const stylepayPaymentSubmit = (values) => {
    dispatch(bankPaymentStart({ ...values, picture: screenshot.file }));
  };

  useEffect(() => {
    dispatch(bankViewStart());
  }, []);

  useEffect(() => {
    if (!bankPayment.loading && Object.keys(bankPayment.data).length > 0) {
      // window.location.replace(bankPayment.data.redirect_url)
      navigate("/success");
      console.log("bankPayment", bankPayment);
    }
  }, [bankPayment]);

  const handleScreenshotImage = (event) => {
    if (event.currentTarget.type === "file") {
      let reader = new FileReader();
      let file = event.currentTarget.files[0];
      reader.onloadend = () => {
        setScreenshot({
          file: file,
          preview: reader.result,
        });
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  };

  return bankView.loading ? <CommonCenterLoader/> : (
    Object.keys(bankView.data).length > 0 ? 
    <>
      <Formik
        initialValues={{
          amount: "",
          payment_id: "",
          admin_bank_id: bankView.data.admin_bank?.admin_bank_id,
        }}
        validationSchema={validationSchema}
        onSubmit={stylepayPaymentSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <FORM className="exchange-form">
            <div className="exchange-box-1">
              <h2>Bank Details</h2>
              <Row>
                <React.Fragment>
                  <Col md={6}>
                    <div className="bank-details">
                      <h4>Beneficiary Name</h4>
                      <p>{bankView.data.admin_bank.beneficiary_name || "Paionia Jiapan(Ka)"}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="bank-details">
                      <h4>Account Number</h4>
                      <p>{bankView.data.admin_bank.account_number || "3505061"}</p>  
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="bank-details">
                      <h4>Bank Name</h4>
                      <p>{bankView.data.admin_bank.bank_name || "MUFG Bnak, Ltd."}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="bank-details">
                      <h4>Bank Code</h4>
                      <p>{bankView.data.admin_bank.bank_code || "0005"}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="bank-details">
                      <h4>Branch Code</h4>
                      <p>{bankView.data.admin_bank.branch_code || "869"}</p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="bank-details">
                      <h4>Account Type</h4>
                      <p>{bankView.data.admin_bank.account_type || "Savings / Futsu"}</p>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="bank-details">
                      <h4>Bank Address</h4>
                      <p>{bankView.data.admin_bank.bank_address || "7 - 1 Marunochi 2-Chrome, Chiyoda-ku Tokyo, Japan"}</p>
                    </div>
                  </Col>
                </React.Fragment>
              </Row>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Amount</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="amount"
                  min="10"
                  placeholder="Enter Amount"
                />
                <ErrorMessage
                  component={"div"}
                  name="amount"
                  className="text-danger"
                />
              </Form.Group>
              {/* <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Card No</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="card_number"
                  placeholder="Enter card number"
                />
                <ErrorMessage
                  component={"div"}
                  name="card_number"
                  className="text-danger"
                />
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formCVV">
                <Form.Label>Transaction Id</Form.Label>
                <Field
                  type="text"
                  className="form-control"
                  name="payment_id"
                  placeholder="Enter Transaction Id"
                />
                <ErrorMessage
                  component={"div"}
                  name="payment_id"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCVV">
                <Form.Label>Screen Shot</Form.Label>
                <Field
                  type="file"
                  className="form-control"
                  name="screen_shot"
                  placeholder="Upload screen shot"
                  accept="image/*"
                  onChange={(event) => handleScreenshotImage(event)}
                />
                <ErrorMessage
                  component={"div"}
                  name="screen_shot"
                  className="text-danger"
                />
              </Form.Group>
              {screenshot.preview !== "" ? (
                <Row>
                  <Col sm={12} md={6} className="mb-3 mb-lg-4">
                    <div className="post-img-preview-sec m-0">
                      <Image
                        alt="#"
                        src={screenshot.preview}
                        className="post-video-preview"
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <div className="exchange-btn-sec">
                <Button
                  className="default-btn w-100"
                  type="submit"
                  disabled={bankPayment.buttonDisable}
                >
                  {bankPayment.buttonDisable ? "Loading" : "Submit"}
                </Button>
              </div>
            </div>
          </FORM>
        )}
      </Formik>
    </> : <NoDataFound />
  );
};

export default BankPaymentIndex;
