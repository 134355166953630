import React, { useState, useEffect } from "react";
import {
  Image,
  Row,
  Col,
  Tab,
  Nav,
  Button,
  Form,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { stylopayPaymentStart } from '../../store/actions/UserAction';

const CardIndex = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const buyData = useSelector(state => state.user.buyData);
  const stylopayPayment = useSelector(state => state.user.stylopayPayment);

  const validationSchema = Yup.object().shape({
    card_number: Yup.string().required("Amount is required").min(10),
    card_number: Yup.string().required("Card number is required")
      .matches(/^[0-9]{16}$/, "Invalid card"),
    cvv: Yup.string().required("CVV is required")
      .matches(/^[0-9]{3}$/, "Invalid CVV"),
    expiry_month: Yup.string()
      .required("Month is required")
      .test('valid-month', 'Invalid month', (value) => {
        const intValue = parseInt(value, 10);
        return intValue >= 1 && intValue <= 12;
      })
      .transform((value) => (value.length === 1 ? `0${value}` : value)),
    expiry_year: Yup.number()
      .required("Year is required")
      .min(new Date().getFullYear() % 100, "Invalid")
      .max(99, "Year must be two digits")
      .integer("Year must be a number"),
  });

  const stylepayPaymentSubmit = (values) => {
    console.log(values)
    dispatch(stylopayPaymentStart(values));
  };

  useEffect(() => {
    if (!stylopayPayment.loading && Object.keys(stylopayPayment.data).length > 0) {
      window.location.replace(stylopayPayment.data.redirect_url)
    }
  }, [stylopayPayment])

  return (
    <>
      <Formik
        initialValues={{
          amount: "",
          card_number: "",
          cvv: "",
          expiry_month: "",
          expiry_year: "",
        }}
        validationSchema={validationSchema}
        onSubmit={stylepayPaymentSubmit}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <FORM className="exchange-form">
            <div className="exchange-box-1">
              <h2>Card Details</h2>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Amount</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="amount"
                  min='10'
                  placeholder="Enter Amount"
                />
                <ErrorMessage
                  component={"div"}
                  name="amount"
                  className="text-danger"
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formCardNumber">
                <Form.Label>Card No</Form.Label>
                <Field
                  type="number"
                  className="form-control"
                  name="card_number"
                  placeholder="Enter card number"
                />
                <ErrorMessage
                  component={"div"}
                  name="card_number"
                  className="text-danger"
                />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formExpiryMonth">
                    <Form.Label>Expire Month</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="expiry_month"
                      placeholder="MM"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length === 1 && value !== '0' && value !== '1') {
                          setFieldValue('expiry_month', `0${value}`);
                        } else {
                          setFieldValue('expiry_month', value);
                        }
                      }}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="expiry_month"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3" controlId="formExpiryYear">
                    <Form.Label>Expire Year</Form.Label>
                    <Field
                      type="number"
                      className="form-control"
                      name="expiry_year"
                      placeholder="YY"
                    />
                    <ErrorMessage
                      component={"div"}
                      name="expiry_year"
                      className="text-danger"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-3" controlId="formCVV">
                <Form.Label>CVV</Form.Label>
                <Field
                  type="text"
                  className="form-control"
                  name="cvv"
                  placeholder="Enter CVV"
                />
                <ErrorMessage
                  component={"div"}
                  name="cvv"
                  className="text-danger"
                />
              </Form.Group>
              <div className="exchange-btn-sec">
                <Button
                  className="default-btn w-100"
                  type="submit"
                  disabled={stylopayPayment.buttonDisable}
                >
                  {stylopayPayment.buttonDisable
                    ? "Loading"
                    : "Submit"}
                </Button>
              </div>
            </div>
          </FORM>
        )}
      </Formik>
    </>
  );
};

export default CardIndex;
