import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import MainLayout from "../layouts/MainLayout";
import { ToastContainer } from "react-toastify";
import StaticIndex from "../Static/StaticIndex";
import BuyIndex from "../Buy/BuyIndex";
import LoginIndex from "../Auth/LoginIndex"
import RegisterIndex from "../Auth/RegisterIndex"
import ProfileIndex from "../Profile/ProfileIndex";
import EditProfileIndex from "../Profile/EditProfileIndex";
import PaymentResponse from "../Buy/PaymentResponse";
import BankPaymentIndex from "../Buy/BankPaymentIndex";
import TransactionIndex from "../Transaction/TransactionIndex";

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId = localStorage.getItem("userId");
  let accessToken = localStorage.getItem("token");
  let authentication =
    userId && accessToken ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        {/* <script src={configuration.get("configData.head_scripts")}></script> */}
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route
            path={"/card-payment"}
            element={
              <PrivateRoute
                component={BuyIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/bank-payment"}
            element={
              <PrivateRoute
                component={BuyIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/usd-payment"}
            element={
              <PrivateRoute
                component={BuyIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/page/:static_page_unique_id"}
            element={
              <AppRoute
                component={StaticIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/"}
            element={
              <AppRoute
                component={LoginIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/register"}
            element={
              <AppRoute
                component={RegisterIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute
                component={ProfileIndex}
                layout={MainLayout}
              />
            }
          />
           <Route
            path={"/edit-profile"}
            element={
              <PrivateRoute
                component={EditProfileIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/success"}
            element={
              <PrivateRoute
                component={PaymentResponse}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/fail"}
            element={
              <PrivateRoute
                component={PaymentResponse}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/transactions"}
            element={
              <PrivateRoute
                component={TransactionIndex}
                layout={MainLayout}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
