export const getCommonInitialState = (data = {}) => ({
  data: data,
  loading: true,
  error: false,
  buttonDisable: false,
});

export const getCommonStart = (data = {}) => ({
  data: data,
  loading: true,
  error: false,
  buttonDisable: true,
});

export const getCommonSuccess = (data) => ({
  data: data,
  loading: false,
  error: false,
  buttonDisable: false,
});

export const getCommonFailure = (error) => ({
  data: {},
  loading: false,
  error: error,
  buttonDisable: false,
});