import React from "react"
import Skeleton from "react-loading-skeleton";
import {
	Container,
	Row,
	Col,
	Image,
	InputGroup,
	Form,
	ProgressBar,
} from "react-bootstrap";

const StaticPageLoader = (props) => (

	<>
		<div className="contact-sec">

			<Skeleton height={200} />

		</div>

		<div className="about-us-sec sm-padding">
			<Container>
				<div className="about-us-box">
					<Skeleton count={4} />
				</div>
			</Container>
		</div>
	</>
)

export default StaticPageLoader;