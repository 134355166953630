import axios from "axios";
import { apiConstants } from "./Constant/constants";
export const apiUrl = "https://eficard-bc.rare-able.com/api/v1/"; // Production Mode

const Environment = {
  postMethod: async (action, object) => {

    const url = apiUrl + action;

    let formData = new FormData();

    var socialLoginUser = 0;

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);

      if (key === "social_unique_id") {
        socialLoginUser = 1;
      }
    }

    let userId =
      localStorage.getItem("userId") !== "" &&
        localStorage.getItem("userId") !== null &&
        localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";

    const accessToken = localStorage.getItem("token") ?
            localStorage.getItem("token")
            : sessionStorage.getItem("token") ?
                sessionStorage.getItem("token")
                : "";
    let config = { headers: { Authorization: 'Bearer ' + accessToken } };

    // By Default Id and token

    formData.append("id", userId);
    formData.append("token", accessToken);

    // By Default added device type and login type in future use
    if (!socialLoginUser) {
      formData.append("login_by", apiConstants.LOGIN_BY);
    }

    formData.append("device_type", apiConstants.DEVICE_TYPE);
    formData.append("device_token", apiConstants.DEVICE_TOKEN);
    let response = await axios.post(url, formData, config);
    if (response.data.error_code && response.data.error_code === 1004) {
      window.location.replace("/logout");
    }

    return response;
  },

  getMethod: async (action, object) => {
    let userId =
      localStorage.getItem("userId") !== "" &&
        localStorage.getItem("userId") !== null &&
        localStorage.getItem("userId") !== undefined
        ? localStorage.getItem("userId")
        : "";
    let accessToken =
      localStorage.getItem("accessToken") !== "" &&
        localStorage.getItem("accessToken") !== null &&
        localStorage.getItem("accessToken") !== undefined
        ? localStorage.getItem("accessToken")
        : "";

    const url = apiUrl + action;

    let formData = new FormData();

    // By Default Id and token

    formData.append("id", userId);
    formData.append("token", accessToken);

    // append your data
    for (var key in object) {
      formData.append(key, object[key]);
    }

    // By Default added device type and login type in future use

    formData.append("login_by", apiConstants.LOGIN_BY);
    formData.append("device_type", apiConstants.DEVICE_TYPE);
    formData.append("device_token", apiConstants.DEVICE_TOKEN);

    return await axios.get(url, formData);
  },
  getConversion: async (from, to) => {
    return await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${from}&vs_currencies=${to}`);
  }
};

export default Environment;
