import {
  AMOUNT_CONVERSION_FAILURE,
  AMOUNT_CONVERSION_START,
  AMOUNT_CONVERSION_SUCCESS,
  SET_WALLET_DETAILS,
} from './ActionConstant';


export function setWalletDetails(data) {
  return {
    type: SET_WALLET_DETAILS,
    data,
  }
}

export function amountConversionStart(data) {
  return {
    type: AMOUNT_CONVERSION_START,
    data,
  }
}

export function amountConversionSuccess(data) {
  return {
    type: AMOUNT_CONVERSION_SUCCESS,
    data,
  }
}

export function amountConversionFailure(error) {
  return {
    type: AMOUNT_CONVERSION_FAILURE,
    error,
  }
}